import { render, staticRenderFns } from "./NewsSearch.vue?vue&type=template&id=14813004&scoped=true&"
import script from "./NewsSearch.vue?vue&type=script&lang=js&"
export * from "./NewsSearch.vue?vue&type=script&lang=js&"
import style0 from "./NewsSearch.vue?vue&type=style&index=0&id=14813004&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14813004",
  null
  
)

export default component.exports