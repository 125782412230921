<template>
  <div>
    <div
      class="news-item"
      v-for="item in news"
      :key="item.id"
      @click="newsDetails(item.id)"
    >
      <div class="time-box">
        <div class="monthDay">
          <div>{{ item.month + '-' }}</div>
          <div>{{item.day}}</div>
        </div>
        <div class="year">{{ item.year }}</div>
      </div>
      <div class="content-box">
        <div class="title">
          {{ item.title }}
        </div>
        <div class="content">
          {{ item.descr }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    news: Array,
  },
  methods: {
    newsDetails(id) {
      this.$router.push({
        name: "NewsDetails",
        params: {
          id: id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.news-item {
  width: 100%;
  margin-bottom: 75px;
  display: flex;
  cursor: pointer;
  .time-box {
    // width: 90px;
    height: 100%;
    padding: 8px 5px;
    .monthDay {
      color: #e95506;
      font-size: 50px;
      line-height: 58px;
      font-weight: 600;
      font-family: DINAlternate-Bold, DINAlternate;
      display: flex;
    }
    .year {
      color: #999;
      font-size: 21px;
      line-height: 25px;
      font-family: DINAlternate-Bold, DINAlternate;
      font-weight: bold;
      margin-top: 6px;
    }
  }
  .content-box {
    flex: 1;
    height: 100%;
    padding: 15px 0 0 30px;
    .title {
      font-size: 30px;
      font-weight: 600;
      color: #000;
      margin-bottom: 10px;
      font-family: "苹方-简 中黑体";
      src: url("../../assets/fonts/PINGFANG HEAVY.TTF");
    }
    .content {
      font-size: 22px;
      color: #666;
      font-family: "苹方-简 常规体";
      src: url("../../assets/fonts/PINGFANG MEDIUM.TTF");
    }
  }
}
</style>
