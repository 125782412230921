<template>
  <div class="news-lists">
    <div class="news-left">
      <news-content :news="fourNews" />
      <div class="check-more" @click="checkMore">
        <span>{{ moreTitle }}</span>
        <img src="../../assets/images/down-gray.png" />
      </div>
    </div>
    <div class="news-right">
      <news-search @search="search" @cate="cate" :yearsLists="yearsLists" />
    </div>
  </div>
</template>
<script>
import NewsContent from "./NewsContent.vue";
import NewsSearch from "./NewsSearch.vue";

import { apiGetNews, apiGetSearch, apiGetCate } from "../../api/index";
export default {
  components: {
    NewsSearch,
    NewsContent,
  },
  data() {
    return {
      news: [],
      yearsLists: [],
      fourNews: [],
      moreTitle: "查看更多",
    };
  },
  watch: {
    fourNews: function(val) {
      let length = val.length;
      if (length === this.news.length) {
        this.moreTitle = "没有更多了～";
      } else {
        this.moreTitle = "查看更多";
      }
    },
  },
  methods: {
    init() {
      apiGetNews().then((data) => {
        if (data.data.status == 1) {
          this.news = data.data.news;
          this.fourNews = this.news.slice(0, 4);
          this.yearsLists = data.data.year;
        }
      });
    },
    search(val) {
      apiGetSearch(val).then((data) => {
        if (data.data.status == 1) {
          this.news = data.data.news;
          this.fourNews = this.news.slice(0, 4);
        }
      });
    },
    cate(val) {
      apiGetCate(val).then((data) => {
        if (data.data.status == 1) {
          this.news = data.data.detail;
          this.fourNews = this.news.slice(0, 4);
        }
      });
    },
    checkMore() {
      if (this.fourNews.length < this.news.length) {
        this.fourNews.push(this.news[this.fourNews.length]);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
.news-lists {
  width: 100%;
  padding: 0 160px 20px;
  height: 100%;
  display: flex;
  .news-right {
    width: 450px;
    padding: 20px 0 10px 50px;
  }
  .news-left {
    flex: 1;
    padding: 10px 15px 10px 0;

    .check-more {
      width: 100%;
      height: 100px;
      background-color: #efefef;
      text-align: center;
      color: #000;
      line-height: 100px;
      font-size: 30px;
      margin-top: 58px;
      font-family: "苹方-简 中黑体";
      src: url("../../assets/fonts/PINGFANG HEAVY.TTF");
      cursor: pointer;
      border-radius: 10px;
      img {
        width: 26px;
        height: 26px;
        vertical-align: middle;
        margin-left: 10px;
      }
    }
  }
}
</style>
