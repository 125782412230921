<template>
  <div>
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        v-model="keywords"
        placeholder="输入关键字"
      />
      <div class="searchIcon" @click="search">
        <span class="input-group-text">
          <img src="../../assets/images/search.png" />
        </span>
      </div>
    </div>
    <div class="input-group">
      <div v-show="!years" class="selectTitle">选择年份</div>
      <div class="searchBox">
        <select v-model="years" @change="cate">
        <option
          v-for="item in yearsLists"
          :key="item.id"
          :label="item.years"
          :value="item.years"
        >
        </option>
      </select>
      </div>
      <div class="input-group-append">
        <span class="input-group-text"
          ><img src="../../assets/images/down.png"
        /></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    yearsLists: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      keywords: "",
      years: "",
    };
  },
  methods: {
    search() {
      this.$emit("search", this.keywords.trim());
    },
    cate() {
      this.$emit("cate", this.years);
    },
  },
};
</script>
<style lang="less" scoped>
.input-group {
  position: relative;
  width: 400px;
  height: 84px;
  line-height: 84px;
  margin-bottom: 33px;
  border-radius: 10px;
  font-family: "苹方-简 常规体";
  src: url("../../assets/fonts/PINGFANG MEDIUM.TTF");
  input[type="text"] {
    padding-left: 20px;
    font-size: 28px;
    border-radius: 10px;
  }
  /deep/input[type="text"]:focus {
    outline: none;
  }
  .searchIcon{
    width: 84px;
    height: 84px;
    .input-group-text {
      width: 100%;
      height: 100%;
      line-height: 84px;
      padding: 0;
      background: #CCCCCC;
      display: block;
    }
  }
  .input-group-append {
    width: 84px;
    height: 84px;
    position: absolute;
    right: 0;
    z-index: -1;
    .input-group-text {
      width: 100%;
      height: 100%;
      padding: 0;
      background: #CCCCCC;
      img {
        width: 38px;
        height: 38px;
        margin: 0 auto;
      }
    }
  }
  .selectTitle{
    position: absolute;
    font-size: 28px;
    color: #6c757d;
    padding-left: 20px;
    z-index: -1;
    border-radius: 5px;
  }
  .searchBox{
    width: 100%;
    border-radius: 5px;
     select {
        border-radius: 10px;
        width: 100%;
        // height: 100%;
        flex: 1;
        font-size: 28px;
        text-indent: 10px;
        appearance: none;
        border:1px solid #ced4da;
        color: #999;
        outline: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        background: transparent;
        option{
          font-size: 20px;
          line-height: 50px;
          color:#999;
        }
      }
  }
 
}
</style>
