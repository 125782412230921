<template>
  <div class="group-news">
    <property-title :backgroundTitle="backgroundTitle" :title="title" />
    <news-lists />
  </div>
</template>
<script>
import PropertyTitle from "../../components/common/PropertyTitle";
import NewsLists from "../../components/news/NewsLists";
export default {
  components: {
    PropertyTitle,
    NewsLists,
  },
  metaInfo: {
    title: '爱情地产',
    meta: [{
      name: 'keyWords',
      content: '爱情地产'
    },
    {
      name: 'description',
      content: ''
    }]
  },
  data() {
    return {
      backgroundTitle: "NEWS",
      title: "集团要闻",
    };
  },
};
</script>
<style lang="less" scoped>
.property-title  {
  margin-bottom: 67px;
}
</style>
